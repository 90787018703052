e4.bootstrap.carousel = {
    init: function () {
        e4.bootstrap.carousel.bindEvents();
    },
    bindEvents: function () {
        jQuery( '.carousel' ).on( 'swipeleft', function () {
            jQuery( this ).carousel( 'next' );
        } ).on( 'swiperight', function () {
            jQuery( this ).carousel( 'prev' );
        } ).find( '.carousel-indicators li' ).on( 'mouseenter', function () {
            jQuery( this ).trigger( 'click' );
        } );
    }
};

