e4.cookies = {
    init: function () {
        e4.cookies.setWarning();
    },
    setWarning: function () {
        var jCookieMessage = jQuery( '.e-cookie-message' );
        if ( jCookieMessage.length === 1 ) {
            jCookieMessage.find( '.CookieOptInLevelConfig' ).on( 'click', function ( event ) {
                event.preventDefault();
                jQuery.get( this.href );
                jCookieMessage.addClass( "accepted" );
            } );
        }
    }
};

