e4.ecom.product = {
    init: function () {
        if ( jQuery( '.js-e-product-form' ).length > 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.ecom.product.handlebars.compileTemplates();
            } );
            e4.ecom.product.bindEvents();
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-product-form' ).on( 'submit', function ( event ) {
            e4.ecom.product.addToCart.submit( event );
        } );
        jQuery( '#js-e-cart-msg' ).on( 'click', function ( event ) {
            ////console.log(event.target.getAttribute( 'data-dismiss' ));
            if ( jQuery( this ).hasClass( 'is-active' ) === true && ( event.target.getAttribute( 'data-dismiss' ) === 'msg' || event.target.parentNode.getAttribute( 'data-dismiss' ) === 'msg' ) ) {
                e4.ecom.product.addToCart.msg.hide();
            }
            event.stopPropagation();
        } ).on( 'mouseover', e4.ecom.product.addToCart.msg.clearTimeout ).on( 'mouseleave', {
            timeout: e4.settings.ecom.cart.msgHideDelay / 3
        }, e4.ecom.product.addToCart.msg.setTimeout );
    },
    addToCart: {
        msg: {
            timeout: null,
            show: function () {
                e4.ecom.product.addToCart.msg.clearTimeout();
                var jCartMsg = jQuery( '#js-e-cart-msg' ),
                    jHeader = jQuery( '.js-e-header' );
                jCartMsg.css( 'top', function () {
                    var intPosTop = jQuery( window ).scrollTop();
                    if ( jHeader.hasClass( 'is-hidden' ) === false ) {
                        intPosTop += jHeader.height();
                    }
                    //intPosTop += jHeader.offset().top;
                    return Math.floor( intPosTop );
                } );
                jCartMsg.addClass( 'is-active' );
                e4.ecom.product.addToCart.msg.setTimeout();
                jQuery( document ).on( 'click', e4.ecom.product.addToCart.msg.hide );
            },
            hide: function () {
                e4.ecom.product.addToCart.msg.clearTimeout();
                jQuery( document ).off( 'click', e4.ecom.product.addToCart.msg.hide );
                jQuery( '#js-e-cart-msg' ).removeClass( 'is-active' );
            },
            setTimeout: function ( event ) {
                //console.log( event, 'setTimeout' );
                //TODO: check for mobile
                var timeout = e4.settings.ecom.cart.msgHideDelay;
                if ( event !== undefined && event.data !== undefined ) {
                    timeout = Math.round( event.data.timeout || timeout );
                }
                e4.ecom.product.addToCart.msg.timeout = setTimeout( e4.ecom.product.addToCart.msg.hide, timeout );
            },
            clearTimeout: function () {
                //console.log( event, 'clearTimeout' );
                if ( e4.ecom.product.addToCart.msg.timeout ) {
                    e4.ecom.product.addToCart.msg.timeout = clearTimeout( e4.ecom.product.addToCart.msg.timeout );
                }
            }
        },
        submit: function ( event ) {
            //console.log( event );
            event.preventDefault();
            //e4.ecom.product.addToCart.loadingShow();
            var jForm = jQuery( event.target ),
                jqXHR;
            jForm.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
            jqXHR = jQuery.ajax( {
                type: 'POST',
                url: e4.settings.ecom.cart.url,
                data: jForm.serialize(),
                dataType: 'json',
                context: event.target
            } );
            jqXHR.done( function ( data ) {
                e4.ecom.product.addToCart.success( data );
            } );
            jqXHR.fail( function ( data, textStatus, errorThrown ) {
                e4.ecom.product.addToCart.error( data, textStatus, errorThrown );
            } );
            jqXHR.always( function () {
                jForm.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
                //console.log( 'always', data );
            } );
        },
        success: function ( data ) {
            jQuery( '#js-e-cart-msg' ).find( '.js-e-cart-msg-content' ).html( e4.ecom.product.handlebars.cartMsgContent( data.cart ) );
            e4.ecom.product.addToCart.msg.show();
            jQuery( '.js-e-cart-count' ).html( data.cart.TotalProductQuantity );
        },
        error: function ( data, textStatus, errorThrown ) {
            console.log( 'fail', data, textStatus, errorThrown );
        }
    },
    handlebars: {
        compileTemplates: function () {
            var tmpl = jQuery( '#js-handlebars-tmpl-cart-msg-content' ).html();
            e4.ecom.product.handlebars.cartMsgContent = Handlebars.compile( tmpl );
        }
    }
};

