e4.navigation = {
    init: function () {
        e4.navigation.bindEvents();
        e4.navigation.sticky.init();
    },
    bindEvents: function () {
        var jNavContainer = jQuery( '.js-e-nav-container' ).on( 'swipeleft', function () {
            jNavContainer.modal( 'hide' );
        } ).on( 'shown.bs.modal', function () {
            setTimeout( e4.navigation.revealActive, e4.settings.navigation.revealActive.delay );
        } ).on( 'click', 'a', function ( event ) {
            //TODO: Lægges ud i funktion.
            var jThis = jQuery( this );
            if ( jThis.is( '.in [data-toggle="dropdown"]' ) === true ) {
                event.stopPropagation();
            }
            if ( jThis.is( '.in [data-collapse-toggle="true"]' ) === true ) {
                event.preventDefault();
                jQuery( this.getAttribute( 'data-collapse-target' ) ).collapse( 'toggle' );
            }
        } );
    },
    revealActive: function () {
        var jNavContainer = jQuery( '.js-e-nav-container' ),
            jNavItemActive = jNavContainer.find( '.nav-item.is-active' ).last(),
            jNavItemActiveParent = jNavItemActive.closest( '.nav' ).closest( '.nav-item' ),
            jOffsetElement = jNavItemActiveParent,
            intScrollTarget = 0;
        if ( jNavItemActive.length === 1 ) {
            // Hvis parent item ikke findes, eller er for høj til viewport, sættes offset element til det aktive item.
            if ( jNavItemActiveParent.length === 0 || jNavItemActiveParent.height() > jNavContainer.height() ) {
                jOffsetElement = jNavItemActive;
            }
            // Beregn relativt offset til containeren.
            intScrollTarget = jOffsetElement.offset().top - jNavContainer.offset().top;
            jNavContainer.animate( {
                scrollTop: intScrollTarget
            }, Math.floor( intScrollTarget * e4.settings.navigation.revealActive.speed ), e4.settings.navigation.revealActive.easing );
        }
    },
    sticky: {
        init: function () {
            if ( jQuery( '.js-e-page-auto-hide-menu' ).length === 1 ) {
                e4.navigation.sticky.lastScrollTop = jQuery( window ).scrollTop();
                if ( e4.settings.navigation.autoHideTolerance.down === 'auto' ) {
                    e4.settings.navigation.autoHideTolerance.down = jQuery( '.js-e-header' ).height();
                }
                e4.navigation.sticky.requestingAnimation = false;
                e4.navigation.sticky.bindEvents();
            }
        },
        bindEvents: function () {
            jQuery( window ).on( 'scroll', function () {
                if ( e4.navigation.sticky.requestingAnimation === false ) {
                    window.requestAnimationFrame( function () {
                        e4.navigation.sticky.toggleVisibility();
                        e4.navigation.sticky.requestingAnimation = false;
                    } );
                }
                e4.navigation.sticky.requestingAnimation = true;
            } );
        },
        show: function () {
            var jNavContainer = jQuery( '.js-e-header' );
            jNavContainer.removeClass( 'is-hidden' );
        },
        hide: function () {
            var jNavContainer = jQuery( '.js-e-header' );
            jNavContainer.addClass( 'is-hidden' );
        },
        toggleVisibility: function () {
            var windowScrollTop = jQuery( window ).scrollTop();
            e4.navigation.sticky.scrollDirectionCurrent = windowScrollTop > e4.navigation.sticky.lastScrollTop ? 'down' : 'up';
            if ( windowScrollTop <= 0 ) { // Check for iOS scroll bounce
                e4.navigation.sticky.show();
            }
            else if ( Math.abs( windowScrollTop - e4.navigation.sticky.lastScrollTop ) > e4.settings.navigation.autoHideTolerance[ e4.navigation.sticky.scrollDirectionCurrent ] ) {
                if ( e4.navigation.sticky.scrollDirectionCurrent === 'down' && e4.navigation.sticky.scrollDirectionLast !== 'down' ) {
                    e4.navigation.sticky.hide();
                }
                else if ( e4.navigation.sticky.scrollDirectionCurrent === 'up' && e4.navigation.sticky.scrollDirectionLast !== 'up' ) {
                    e4.navigation.sticky.show();
                }
                e4.navigation.sticky.lastScrollTop = windowScrollTop;
                e4.navigation.sticky.scrollDirectionLast = e4.navigation.sticky.scrollDirectionCurrent;
            }
        }
    }
};

